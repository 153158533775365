// vendors
import React from "react"

// components
import Layout from "../components/Layout"
import SEO from "../components/Seo"
import ProfilingGrid from "../views/index/ProfilingGrid"
import AboutSection from "../views/index/About/About"
import EventList from "../views/index/Events"
import { graphql } from "gatsby"

const IndexPage = ({ data }) => {
  const seminarId = data.seminars.edges[0].node.id

  const events = ((data.events || {}).edges || [])
    .filter(({ node: { fromDate } }) => {
      return new Date(fromDate) > Date.now()
    })
    .map(({ node }) => {
      const to =
        node.id !== seminarId
          ? `/evenements/${((node.meta || {}).slug || {}).current}`
          : `/colloque`

      return {
        picture: { ...node.thumbnail?.asset?.fluid, alt: "" },
        date: node.fromDate || null,
        name: node.title || null,
        place: node.placeName || null,
        city: (node.address || {}).city || null,
        onlineUrl: node.online ? node.onlineUrl : null,
        excerpt: "",
        to,
      }
    })

  return (
    <Layout>
      <SEO
        title="Stage d'été médecin et emploi médecin en région"
        description="Vous êtes étudiant en médecine, résident ou externe et recherchez un stage d'été en région? Sarros vous guide et conseille dans l'obtention de votre poste."
        keywords={["stage d'été médecin", "emploi médecin région"]}
        lang="fr"
      />

      <ProfilingGrid />

      <AboutSection />

      <EventList events={events} />
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query HomePageQuery {
    events: allSanityEvent(sort: { order: ASC, fields: fromDate }) {
      edges {
        node {
          id
          address {
            city
          }
          online
          onlineUrl
          fromDate
          placeName
          thumbnail {
            asset {
              fluid {
                src
                srcSet
                srcSetWebp
              }
            }
          }
          title
          meta {
            slug {
              current
            }
          }
        }
      }
    }

    seminars: allSanityEvent(
      filter: {
        meta: { slug: { current: { ne: null } } }
        type: { in: ["seminar"] }
      }
      sort: { fields: fromDate, order: DESC }
      limit: 1
    ) {
      edges {
        node {
          id
        }
      }
    }
  }
`
