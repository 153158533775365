// vendors
import React from "react"
import css from "@emotion/css"
import styled from "@emotion/styled"
import { fontSizes, colors, transition } from "../../../styles/variables"
import mediaQuery from "../../../utils/media-query"
import { between } from "polished"

const TopCardContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: ${(props) => props.backgroundColor};
  height: 100%;
  width: 100%;
  border-radius: ${(props) => props.isCircle && "50%"};
  overflow: hidden;
  transition: opacity ${transition.speed.default} ${transition.curve.default};
`

export const TopCard = ({
  children,
  isCircle = false,
  contentBG = null,
  contentColor = null,
  backgroundColor = null,
  backgroundImage = null,
  ...props
}) => {
  return (
    <TopCardContainer
      backgroundColor={backgroundColor}
      isCircle={isCircle}
      {...props}
    >
      <div
        css={css`
          position: absolute;
          display: flex;
          align-items: center;
          justify-content: center;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          height: 100%;
          width: 100%;
          z-index: 1;
        `}
      >
        <div
          css={css`
            display: flex;
            align-items: center;
            justify-content: center;
            height: ${(243 / 320) * 100}%;
            width: ${(243 / 320) * 100}%;
            background-color: ${contentBG};
            color: ${contentColor};
            border-radius: 50%;
            font-size: ${fontSizes[5] / fontSizes[0]}em;
            line-height: ${70 / 68};
            font-weight: 900;
            text-align: center;

            ${mediaQuery.greaterThen(fontSizes.breakpoint)} {
              height: ${(475 / 720) * 100}%;
              width: ${(475 / 720) * 100}%;

              font-size: ${between(
                `52px`,
                `68px`,
                `${fontSizes.breakpoint}px`,
                "1920px"
              )};
            }

            ${mediaQuery.greaterThen(1920)} {
              font-size: 68px;
            }

            * {
              margin: 0;
            }
          `}
        >
          {children}
        </div>
      </div>

      {backgroundImage && (
        <picture
          css={css`
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            height: 100%;
            width: 100%;

            > * {
              max-width: 100%;
            }
          `}
        >
          <img
            src={backgroundImage.src}
            alt=""
            sizes={`(min-width: 1200px) 50vw, (min-width: 1920px) 720px, 100vw`}
            srcset={backgroundImage.srcSet}
            role="presentation"
          />
        </picture>
      )}
    </TopCardContainer>
  )
}

const HoverCardContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  pointer-events: none;
  background-color: ${colors.white};
  z-index: 1;
  transition: opacity ${transition.speed.default} ${transition.curve.default};
`

export const HoverCard = ({
  children,
  isCircle = false,
  backgroundColor = null,
}) => {
  return (
    <HoverCardContainer>
      <div
        css={css`
          position: absolute;
          display: flex;
          justify-content: center;
          align-items: center;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          height: 100%;
          width: 100%;
          background-color: ${backgroundColor};
          border-radius: ${isCircle && "50%"};
        `}
      >
        <div
          css={css`
            text-align: center;
            width: ${(560 / 720) * 100}%;

            p span {
              display: block;
              font-size: ${fontSizes[2] / fontSizes[0]}em;
              line-height: ${fontSizes[6] / fontSizes[5]};
              font-weight: 900;

              ${mediaQuery.greaterThen(fontSizes.breakpoint)} {
                font-size: ${fontSizes[5] / fontSizes[1]}em;
              }
            }

            a,
            p {
              margin: 0 !important;
            }

            a {
              margin-top: ${fontSizes[3] / fontSizes[0]}em !important;

              ${mediaQuery.lessThen(fontSizes.breakpoint)} {
                font-size: 1em !important;
              }

              ${mediaQuery.greaterThen(fontSizes.breakpoint)} {
                margin-top: ${fontSizes[6] / fontSizes[1]}em !important;
              }
            }
          `}
        >
          {children}
        </div>
      </div>
    </HoverCardContainer>
  )
}

const ProfilingCard = ({ children }) => {
  return (
    <div
      css={css`
        position: relative;
        padding-bottom: 100%;

        :hover {
          ${HoverCardContainer} {
            opacity: 1;
            pointer-events: auto;
          }
          ${TopCardContainer} {
            opacity: 0;
            pointer-events: none;
          }
        }
      `}
    >
      {children}
    </div>
  )
}

export default ProfilingCard
