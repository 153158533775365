// vendors
import React from "react"
import css from "@emotion/css"
import ProfilingCard, { TopCard, HoverCard } from "./ProfilingCard"
import Button from "../../../components/Button/Button"
import { colors } from "../../../styles/variables"
import wrapper from "../../../utils/wrapper"
import mediaQuery from "../../../utils/media-query"
import { useStaticQuery, graphql } from "gatsby"

const IndexProfilingGrid = () => {
  const data = useStaticQuery(graphql`
    query {
      regionImage: file(
        relativePath: { eq: "img-poste-medecin-region-prem.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1024) {
            src
            srcSet
            srcSetWebp
          }
        }
      }
      stageImage: file(relativePath: { eq: "img-stage-d'ete-medecin.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1024) {
            src
            srcSet
            srcSetWebp
          }
        }
      }
      pratiqueImage: file(
        relativePath: { eq: "img-emploi-medecin-region.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1024) {
            src
            srcSet
            srcSetWebp
          }
        }
      }
    }
  `)

  return (
    <div
      css={css`
        display: grid;
        grid-template-columns: 1fr;

        ${mediaQuery.greaterThen(480)} {
          grid-template-columns: repeat(auto-fill, minmax(440px, 1fr));
        }

        ${mediaQuery.greaterThen(1440)} {
          grid-template-columns: repeat(auto-fill, minmax(480.5px, 1fr));
        }

        ${mediaQuery.greaterThen(1024)} {
          ${wrapper.bolt()}
        }
      `}
    >
      <ProfilingCard>
        <TopCard
          contentBG={colors.blueyGreen}
          backgroundImage={data.regionImage.childImageSharp.fluid}
        >
          <p>
            12
            <br />
            régions
          </p>
        </TopCard>

        <HoverCard isCircle backgroundColor={colors.blueyGreen}>
          <p>
            <span>
              Découvrez les
              <br />
              12&nbsp;territoires diversifiés{" "}
            </span>
            situés aux quatre coins du Québec, où vous établir et pratiquer la
            médecine.
          </p>

          <Button tag="link" to="/regions" full>
            Voyagez ici
          </Button>
        </HoverCard>
      </ProfilingCard>

      <ProfilingCard>
        <TopCard
          contentColor={colors.white}
          isCircle
          backgroundImage={data.stageImage.childImageSharp.fluid}
        >
          <p>
            Faire
            <br />
            un stage
          </p>
        </TopCard>

        <HoverCard backgroundColor={colors.dullYellow}>
          <p>
            <span>Un stage d’été est une excellente manière de s’initier </span>
            à la pratique en région. Découvrez les programmes stimulants qui
            vous y attendent pour une expérience hors du commun!
          </p>

          <Button tag="link" to="/stage" full secondary>
            Renseignez-vous ici
          </Button>
        </HoverCard>
      </ProfilingCard>

      <ProfilingCard>
        <TopCard
          contentColor={colors.white}
          isCircle
          backgroundImage={data.pratiqueImage.childImageSharp.fluid}
        >
          <p>Pratiquer</p>
        </TopCard>

        <HoverCard backgroundColor={colors.blueyGreen}>
          <p>
            <span>SARROS vous procure tout le soutien nécessaire </span>
            afin de dénicher un poste en région en accord avec vos besoins, en
            plus d’une aide pour vous y installer.
          </p>

          <Button tag="link" to="/emploi" full>
            Renseignez-vous ici
          </Button>
        </HoverCard>
      </ProfilingCard>

      <ProfilingCard>
        <TopCard backgroundColor={colors.pastelRed} contentBG={colors.white}>
          <p>Événements</p>
        </TopCard>

        <HoverCard isCircle backgroundColor={colors.pastelRed}>
          <p>
            <span>Les régions SARROS viennent à votre rencontre </span>
            pour vous présenter leurs charmes. C’est le moment parfait pour les
            découvrir et poser vos questions. On vous y attend!
          </p>

          <Button tag="link" to="/evenements" full secondary>
            Découvrez-les ici
          </Button>
        </HoverCard>
      </ProfilingCard>
    </div>
  )
}

export default IndexProfilingGrid
