// vendors
import React from "react"
import EventGrid from "../../../components/EventGrid"
import css from "@emotion/css"
import wrapper from "../../../utils/wrapper"
import { fontSizes } from "../../../styles/variables"
import mediaQuery from "../../../utils/media-query"
import IllustrationPathWithPin from "../../../images/IllustrationPathWithPin"

const IndexEventsList = ({ events = [] }) => {
  return (
    <section
      css={css`
        ${wrapper.bolt()}
        margin-top: ${fontSizes[9] / fontSizes[0]}em;
        padding-top: ${150 / fontSizes[0]}em;
        margin-bottom: ${fontSizes[9] / fontSizes[0]}em;
        position: relative;
      `}
    >
      <IllustrationPathWithPin
        pointer
        css={css`
          position: absolute;
          top: 0;
          right: 0;
          max-width: 422px;
          transform: rotateY(180deg) translateY(-50%);
          z-index: -1;
        `}
      />

      <p
        css={css`
          font-size: ${fontSizes[6] / fontSizes[0]}em;
          line-height: ${90 / fontSizes[9]};
          font-weight: 900;
          max-width: 760px;

          ${mediaQuery.greaterThen(fontSizes.breakpoint)} {
            font-size: ${fontSizes[9] / fontSizes[1]}em;
          }
        `}
      >
        Participez
        <br />à ces événements.
      </p>

      <IllustrationPathWithPin
        css={css`
          position: absolute;
          left: 0;
          max-width: 422px;
          transform: translateX(-90%) translateY(-50%) rotateZ(180deg);
          z-index: -1;

          ${mediaQuery.lessThen(1024)} {
            display: none;
          }
        `}
      />

      <EventGrid events={events} />
    </section>
  )
}

export default IndexEventsList
