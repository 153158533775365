// vendors
import React from "react"
import { css } from "@emotion/core"
import Button from "../../../components/Button/Button"
import { colors, fontSizes } from "../../../styles/variables"
import wrapper from "../../../utils/wrapper"

import PinPoint from "../../../images/pinPoint-pastelRed.svg"
import mediaQuery from "../../../utils/media-query"

const IndexAboutSection = () => (
  <section
    id="a-propos"
    css={css`
      ${wrapper.bolt("padding")}
      background-color: ${colors.offWhite};
      position: relative;
      z-index: 1;
    `}
  >
    <div
      css={css`
        max-width: 1130px;
        margin: ${fontSizes[9] / fontSizes[0]}em auto;
        padding: ${fontSizes[9] / fontSizes[0]}em 0;

        ${mediaQuery.greaterThen(fontSizes.breakpoint)} {
          padding: ${234 / fontSizes[0]}em 0;
        }
      `}
    >
      <p
        css={css`
          font-weight: 900;
          font-size: ${fontSizes[2] / fontSizes[0]}em;
          line-height: ${fontSizes[3] / fontSizes[2]};

          ${mediaQuery.greaterThen(fontSizes.breakpoint)} {
            font-size: ${fontSizes[5] / fontSizes[1]}em;
          }
          img {
            max-height: 0.8em;
            display: none;
          }

          :before {
            background-image: url(${PinPoint});
            height: 1em;
            width: 2ch;
            content: "";
            display: inline-block;
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;
          }
        `}
      >
        <img src={PinPoint} alt="" role="presentation" /> — SARROS, c’est le
        Soutien aux Régions pour le Recrutement d’Omnipraticiens et de
        Spécialistes. En d’autres mots, c’est une équipe dont la mission est
        d’informer les médecins actuels et futurs des possibilités de stages et
        d’emplois en région, en plus de les soutenir dans leurs démarches
        d’établissement au cœur des 12 territoires couverts.
      </p>

      <div
        css={css`
          text-align: center;
        `}
      >
        <Button tag="link" to="/regions" full>
          Voir les régions
        </Button>
      </div>
    </div>
  </section>
)

export default IndexAboutSection
